import React from 'react';
import '../../css/App.css';
import 'semantic-ui-css/semantic.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

function Hobbies(){
    return(
        <React.Fragment>
        <div className = "hobbies" id="hobbies">
            <div className="row">
                <div className="col-sm-6" id="otherHobbies">
                        <h1 id="badmintonHeader" style={{fontSize: 34, fontWeight: "bold"}}>I also <FontAwesomeIcon className = 'beat' icon={faHeart} style={{color:"maroon"}} /> Badminton<br></br></h1>
                                <p style={{fontSize: 16}}> Not only do I play the sport, it's also the only sport that I actually watch. I witnessed the live action at 
                                the <a href="https://www.birmingham2022.com/">XXII Commonweath Games finals</a> in 2022 & the <a href="https://www.allenglandbadminton.com/">All England Badminton Championship</a> 2023 semi-finals (and they were
                                unreal).</p>
                        <br></br>
                </div>
                <div className="col-sm-6" id="otherHobbies">
                    <h1 id="otherInterests" style={{fontSize: 35, fontWeight: "bold", textAlign: "center"}}></h1>
                    <p style={{fontSize: 16}}>My other eclectic interests include <b>crosswords</b> (or any word puzzles for that matter - <a href="https://www.nytimes.com/crosswords">NYT Games</a> and <a href="https://www.linkedin.com/games/">LinkedIn Games</a> are my current daily rituals), <b>trivia quizzes</b>, <b><a href="https://www.etymonline.com/">etymologies</a></b>, <b>puns</b>, <b><a href="https://www.youtube.com/playlist?list=PLR7XO54Pktt8_jNjAVaunw1EqqcEAdcow">countries & flags & such things</a></b> (I 
                       spent more time with the dictionary and the atlas as a kid instead of storybooks), and <b>aeroplanes.</b></p>
                    <br></br>
                </div>
            </div>
        </div>
        </React.Fragment>
    );
}

export default Hobbies;