//ref: https://www.digitalocean.com/community/tutorials/react-react-burger-menu-sidebar

import { slide as Menu } from 'react-burger-menu'
import React from 'react';
import '../../css/sidemenu.css';

export default props => {
  return (
    // Pass on our props
    <Menu right {...props}>
      <a className="menu-item" href="#">
        About
      </a>

      <a className="menu-item" href="#languages">
        Languages
      </a>

      <a className="menu-item" href="#hobbies">
        Hobbies
      </a>

      <a className="menu-item" href="#causes">
        Causes
      </a>

      <a className="menu-item" href="#featured">
        Featured
      </a>

      <a className="menu-item" href="#projects">
        Projects
      </a>
    </Menu>
  );
};
