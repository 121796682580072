import React from "react";
import Languages from "./pages/languages";
import Hobbies from './pages/hobbies';
import Causes from './pages/causes';
import Featured from './pages/feature';
import MenuBar from "./assets/menubar";
import Projects from "./pages/projects";
import '../css/pages.css';

/*
* Render all individual sections from here 
*/

function RenderPage(){
return(
    <React.Fragment>
    <MenuBar />
    <br />
    <br />
    <br />
    <br />
    <br />
    <div className="row"><Languages/></div>
    <br />
    <div className="row"><Hobbies/></div>
    <br/>
    <div className="row"><Causes/></div>  
    <br/>
    <div className="row"><Featured/></div>    
    <div className="row"><Projects/></div>    
    <br/>   
    </React.Fragment> 
);
}

export default RenderPage;
