import React from 'react'
import Podcasts from './features/podcasts';
import Articles from './features/articles';
import Videos from './features/videos';
import '../../css/App.css';

function Featured(){
    return(
        <div className = "featured" id="featured">
            <p id="featHeader" style={{fontSize: 50, fontWeight: "bold"}}>I've Been Featured In A Few Places Too</p>
            <p style={{fontSize: 20}}>Themes usually being <b>technology</b>, <b>early careers</b>, <b>studying abroad</b>, <b>mentoring</b> and <b>mental health</b></p>
            <div className = "row" id="podcast"><Podcasts/></div>
            <br></br>
            <div className = "row" id="article"><Articles/></div> 
            <br></br>  
            <div className = "row" id="video"><Videos/></div>   
        </div>
    );
}

export default Featured;