import React from 'react';
import '../../css/App.css';
import '../../css/loading.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faLightbulb} from '@fortawesome/free-solid-svg-icons';


function Projects(){
    return(
        <React.Fragment>
            <div className = "projects" id="projects">
                <div className="row">                            
                    <div id="projectsHeader" style={{fontSize: 50, fontWeight: "bold", paddingTop:100}}>Pet Projects &nbsp;<FontAwesomeIcon className='beat' icon={faLightbulb} style={{color:"orange"}} /></div>
                    <div className="projectsContent">
                        <br></br>
                        <p id="petproj">I sometimes dabble around with programming languages & tools to build & learn something new, which I will share here once ready! </p>
                        <br></br>
                        <div className="loading-bar">
                            <div className="loading-bar-fill"></div>
                        </div>
                    </div>                
                </div>
            </div>
        </React.Fragment>
    );
}

export default Projects;